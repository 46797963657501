import useChannel from '@/composables/useChannel'
import usePageTitle from '@/composables/usePageTitle'
import useSidebar from '@/composables/useSidebar'
import { useAuthStore } from '@/stores/auth'
import Sensors from '@/utils/sensors'
import { locationComToCn } from '@/utils/url'
import { nextTick } from 'vue'
import { RouterView, createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'

export const RoutesMap = {
  unlogin: {
    index: 'unloginIndex'
  },
  rule: {
    privacy: 'privacyIndex',
    saas: 'saasIndex'
  },
  home: {
    homeName: 'home',
    index: 'homeIndex',
    nash: 'homeNash',
    case: 'homeCase'
  },
  auth: {
    authName: 'auth',
    login: 'authLogin',
    loginInvite: 'authLoginInvite',
    logout: 'authLogout',
    verify: 'authVerify'
  },
  safe: 'safe',
  chat: {
    chatName: 'chat',
    c: 'chatC',
    homeC: 'chatHomeC',
    navigator: 'navigator'
  },
  flow: {
    index: 'flowIndex',
    intention: 'flowIntention'
  },
  tranning: {
    bot: 'tranningBot',
    report: 'tranningReport',
    reportContext: 'tranningReportContext',
    botChat: 'tranningBotChat'
  },
  manager: {
    managerName: 'manager',
    center: 'managerCenter',
    create: 'managerCreate'
  },
  namespace: {
    namespaceName: 'namespace',
    personalSetting: 'namespacePersonalSetting',
    management: 'namespacePersonalManagement',
    summary: 'namespaceSummary',
    income: 'namespaceIncome'
  },
  guide: {
    first: 'guideFirst',
    yzl: 'youzanLoading'
  },
  namespaceSwitch: 'namespaceSwitch',
  endPlatform: {
    adCollectForm: 'endPlatformadCollectForm'
  },
  pay: {
    payRediect: 'payRediect'
  },
  products: {
    bot: 'bot',
    intelligentQualityInspection: 'intelligentQualityInspection',
    customReports: 'customReports',
    marketingBot: 'marketingBot',
    marketingReach: 'marketingReach',
    speechBot: 'speechBot',
    SCRM: 'SCRM',
    privateDeployment: 'privateDeployment'
  },
  solutions: {
    ecommerce: 'ecommerce',
    education: 'education',
    hr: 'hr',
    enterpriseService: 'enterpriseService',
    overseas: 'overseas'
  },
  QAQuality: {
    count: 'count'
  },
  intelligentOptimize: {
    policy: 'policy'
  }
}

const unloginRoutes = [
  {
    name: RoutesMap.unlogin.index,
    path: '/unlogin/:botId',
    component: () => import('@/views/training/unlogin/index.vue')
  }
]

const ruleRoutes = [
  {
    name: RoutesMap.rule.privacy,
    path: '/rule/privacy',
    component: () => import('@/views/rule/privacy/index.vue')
  },
  {
    name: RoutesMap.rule.saas,
    path: '/rule/saas',
    component: () => import('@/views/rule/saas/index.vue')
  }
]

const coreRoutes = [
  {
    path: '/',
    component: () => import('@/views/home/index.vue'),
    children: [
      {
        name: RoutesMap.home.index,
        path: '',
        component: () => import('@/views/home/home.vue')
      },
      {
        name: RoutesMap.home.case,
        path: 'case',
        component: () => import('@/views/home/case.vue')
      }
    ]
  },
  {
    name: RoutesMap.home.nash,
    path: '/nash',
    component: () => import('@/views/nash/index.vue')
  },
  {
    path: '/wxh/:botSlug',
    component: () => import('@/views/wxh/index.vue')
  },
  {
    path: '/wjs/:botSlug',
    component: () => import('@/views/wjs/index.vue')
  },
  {
    name: RoutesMap.auth.login,
    path: '/auth/login',
    component: () => import('@/views/auth/LoginView.vue')
  },
  {
    name: RoutesMap.auth.loginInvite,
    path: '/auth/loginInvite',
    component: () => import('@/views/auth/LoginInvitationView.vue')
  },
  {
    name: RoutesMap.auth.logout,
    path: '/auth/logout',
    component: () => import('@/views/auth/LogoutView.vue')
  },
  {
    path: '/error',
    component: RouterView,
    children: [
      {
        path: '403',
        component: () => import('@/views/error/Error403.vue')
      },
      {
        path: '404',
        component: () => import('@/views/error/Error404.vue')
      },
      {
        path: '500',
        component: () => import('@/views/error/Error500.vue')
      }
    ]
  },
  {
    name: RoutesMap.safe,
    path: '/link',
    component: () => import('@/views/redirect/RedirectView.vue')
  },
  {
    name: RoutesMap.endPlatform.adCollectForm,
    path: '/endPlatform/adCollectForm/:formId/:uid',
    component: () => import('@/views/endPlatform/AdCollectForm.vue')
  }
]

// 产品的路由
const productsRoutes = [
  {
    path: '/',
    component: () => import('@/views/home/index.vue'),
    children: [
      // 客服机器人
      {
        name: RoutesMap.products.bot,
        path: '/products/bot',
        component: () => import('@/views/products/bot/index.vue')
      },
      // 智能质检
      {
        name: RoutesMap.products.intelligentQualityInspection,
        path: '/products/intelligentQualityInspection',
        component: () => import('@/views/products/intelligentQualityInspection/index.vue')
      },
      // 自定义报表
      {
        name: RoutesMap.products.customReports,
        path: '/products/customReports',
        component: () => import('@/views/products/customReports/index.vue')
      },
      // 营销机器人
      {
        name: RoutesMap.products.marketingBot,
        path: '/products/marketingBot',
        component: () => import('@/views/products/marketingBot/index.vue')
      },
      // 营销触达
      {
        name: RoutesMap.products.marketingReach,
        path: '/products/marketingReach',
        component: () => import('@/views/products/marketingReach/index.vue')
      },
      // 语音机器人
      {
        name: RoutesMap.products.speechBot,
        path: '/products/speechBot',
        component: () => import('@/views/products/speechBot/index.vue')
      },
      // SCRM
      {
        name: RoutesMap.products.SCRM,
        path: '/products/SCRM',
        component: () => import('@/views/products/SCRM/index.vue')
      },
      // 私有化部署
      {
        name: RoutesMap.products.privateDeployment,
        path: '/products/privateDeployment',
        component: () => import('@/views/products/privateDeployment/index.vue')
      }
    ]
  }
]

// 解决方案的路由
const solutionsRoutes = [
  {
    path: '/',
    component: () => import('@/views/home/index.vue'),
    children: [
      // 电商行业解决方案
      {
        name: RoutesMap.solutions.ecommerce,
        path: '/solutions/ecommerce',
        component: () => import('@/views/solutions/ecommerce/index.vue')
      },
      {
        name: RoutesMap.solutions.overseas,
        path: '/solutions/overseas',
        component: () => import('@/views/solutions/overseas/index.vue')
      },
      // 教育行业解决方案
      {
        name: RoutesMap.solutions.education,
        path: '/solutions/education',
        component: () => import('@/views/solutions/education/index.vue')
      },
      // HR行业解决方案
      {
        name: RoutesMap.solutions.hr,
        path: '/solutions/hr',
        component: () => import('@/views/solutions/hr/index.vue')
      },
      // 企服行业解决方案
      {
        name: RoutesMap.solutions.enterpriseService,
        path: '/solutions/enterpriseService',
        component: () => import('@/views/solutions/enterpriseService/index.vue')
      }
    ]
  }
]

// 训练机器人
const trainningRoutes = [
  {
    path: 't',
    component: () => import('@/views/training/index.vue'),
    redirect: '/error/404',
    children: [
      {
        name: RoutesMap.tranning.bot,
        // 训练中心-当前机器人
        path: 'bot/:botId',
        meta: { requiresAuth: true },
        children: [
          {
            name: RoutesMap.tranning.botChat,
            path: 'chat',
            component: () => import('@/views/training/chat/index.vue')
          },
          {
            name: RoutesMap.tranning.report,
            path: 'report/:type?/:chatId?',
            component: () => import('@/views/training/report/index.vue')
          }
        ]
      }
    ]
  }
]

// 问答质量统计
const QAQualityRoutes = [
  {
    name: RoutesMap.QAQuality.count,
    path: '/QAQuality/count',
    component: () => import('@/views/QAQuality/count/index.vue')
  }
]

// 智能优化策略
const intelligentOptimizeRoutes = [
  {
    name: RoutesMap.intelligentOptimize.policy,
    path: '/intelligentOptimize/policy',
    component: () => import('@/views/intelligentOptimize/policy/index.vue')
  }
]

// 空间
const spaceManager = [
  {
    path: 'space',
    component: RouterView,
    children: [
      {
        name: RoutesMap.namespace.personalSetting,
        path: 'personal',
        component: () => import('@/views/space/personalSetting.vue')
      },
      {
        name: RoutesMap.namespace.income,
        path: 'income',
        component: () => import('@/views/space/personalIncome.vue')
      },
      {
        name: RoutesMap.namespace.summary,
        path: 'summary/:type?',
        component: () => import('@/views/space/summarySpace.vue')
      },
      {
        path: ':spaceId',
        component: () => import('@/views/space/index.vue'),
        children: [
          {
            name: RoutesMap.namespace.management,
            path: '',
            component: () => import('@/views/space/personalSpace.vue')
          }
        ]
      }
    ]
  }
]

// 引导
const guideRoutes = [
  {
    name: RoutesMap.guide.first,
    path: '/guide/first',
    component: () => import('@/views/guide/firstGuide.vue')
  }
]

const namespaceSwitch = [
  {
    name: RoutesMap.namespaceSwitch,
    path: '/spaceSwitch',
    component: () => import('@/views/space/spaceSwitch.vue')
  }
]

const loginedRoutes = [
  {
    path: '/',
    component: () => import('@/layout/MainLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      ...trainningRoutes, // 训练中心
      ...QAQualityRoutes, // 问答质量统计
      ...intelligentOptimizeRoutes, // 智能优化策略
      ...spaceManager
      // ...vipManager
    ]
  }
]

// pay redirect
const payRedirect = [
  {
    name: RoutesMap.pay.payRediect,
    path: '/pay/redirect',
    beforeEnter: (to, from, next) => {
      const redirectUrl = to.query.redirect
      if (redirectUrl) {
        window.location.href = redirectUrl
      } else {
        next('/error/404') // 或者 next(Error('redirect URL not provided'));
      }
    }
  }
]

const finalRoutes = [
  {
    path: '/:any+',
    redirect: '/error/404'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...unloginRoutes,
    ...ruleRoutes,
    ...coreRoutes,
    ...loginedRoutes,
    ...namespaceSwitch,
    ...finalRoutes,
    ...guideRoutes,
    ...payRedirect,
    ...productsRoutes,
    ...solutionsRoutes
  ] as RouteRecordRaw[]
})
router.beforeEach((to) => {
  const { shareChannel } = useChannel()
  const sensors = new Sensors()
  const { saInstance } = sensors
  const { drawerVisible } = useSidebar()
  drawerVisible.value = false

  locationComToCn()
  usePageTitle(to.meta?.title)
  saInstance?.track('channel-source', {
    name: 'channel来源',
    type: 'channel-source',
    data: shareChannel
  })
  // useCheckDomain(to)
  const authStoreI = useAuthStore()
  if (to.meta.requiresAuth && !authStoreI.authToken) {
    let query
    // 通过 redirect 参数保存当前所在的位置，以便登录后返回
    // 如果当前是首页，就不用存了，因为登录后默认回首页
    if (to.fullPath !== '/') query = { redirect: to.fullPath }
    return {
      path: '/auth/login',
      query
    }
  }
})

router.afterEach((to) => {
  nextTick(() => {
    const sensors = new Sensors()
    const { saInstance } = sensors
    saInstance?.quick('autoTrackSinglePage')
  })
})

// 由于当前部署，采用镜像构建并覆盖全部新文件，解决网站有老用户停留在老页面，但旧资源地址在服务器已被移除掉的问题
// 期待 Vite 的解决方案：https://github.com/vitejs/vite/issues/11804
router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload()
    } else {
      window.location.href = to.fullPath
    }
  }
})

export default router
