const crmTenantName = import.meta.env.VITE_CRM_TENANT_NAME
const crmPwd = import.meta.env.VITE_CRM_PWD
const crmUsername = import.meta.env.VITE_CRM_USER_NAME
const baiduAk = import.meta.env.VITE_APP_BAIDU_MAP_AK
const baiduSk = import.meta.env.VITE_APP_BAIDU_MAP_SK
const baiduAppId = import.meta.env.VITE_APP_BAIDU_APP_ID

export function getCurrentEnv() {
  return import.meta.env.VITE_APP_ENV || 'prod'
}

const config = {
  env: 'localhost',
  // 本地环境
  localhost: {
    env: 'development',
    baseURL: 'https://api.chato.cn',
    rewriteBaseURL: 'http://localhost:3300/',
    uploadBaseURL: 'https://api.chato.cn',
    wwwBaseURL: globalThis.location.origin,
    scriptURL: 'https://chato.cn',
    socketURL: 'api.chato.cn/chato/ws',
    socketCRMURL: 'api.chato.cn/chato/crm/ws',
    asrSocketURL: 'wss://file.chato-ai.com/asr',
    crmTenantName,
    crmPwd,
    crmUsername,
    baiduAk,
    baiduSk,
    baiduAppId
  },
  // 开发环境
  development: {
    BASE_URL: 'www.dev-backend.com',
    API_URL: '/dev-api'
  },
  // 测试环境
  test: {
    BASE_URL: 'www.test-backend.com',
    API_URL: '/test-api'
  },
  // 生产环境
  production: {
    env: 'production',
    baseURL: 'https://api.chato.cn',
    rewriteBaseURL: 'https://chant-api.antopia.net',
    uploadBaseURL: 'https://api.chato.cn',
    wwwBaseURL: globalThis.location.origin,
    scriptURL: 'https://chato.cn',
    socketURL: 'api.chato.cn/chato/ws',
    socketCRMURL: 'api.chato.cn/chato/crm/ws',
    asrSocketURL: 'wss://file.chato-ai.com/asr',
    crmTenantName,
    crmPwd,
    crmUsername,
    baiduAk,
    baiduSk,
    baiduAppId
  }
}

switch (globalThis.location.hostname) {
  // 开发环境前端域名
  case 'www.dev-frontend.com':
    config.env = 'development'
    break
  // 测试环境前端域名
  case 'www.test-frontend.com':
    config.env = 'test'
    break
  // 生产环境前端域名
  case 'chant.antopia.net':
    config.env = 'production'
    break
  default:
    config.env = 'localhost'
    break
}
export const currentEnvConfig = config[config.env]
